<template>
    <div>
        <div v-show="clients.length > 0">
            <webix-ui :config="ui" v-model="clients"></webix-ui>
            <div id="clientPaging"></div>
        </div>
        <div style="text-align: center;" v-show="clients.length == 0">
             <h2>There are no active members</h2>
        </div>

    </div>
</template>

<script>
import clientDetails from "./clientDetails.vue";
import Swal from "sweetalert2";
import { clientMethods, clientComputed } from "@/state/helpers";
export default {
    //eslint-disable-next-line
    components: { clientDetails },
    data: () => ({}),
    computed: {
        ...clientComputed,
        ui() {
            //eslint-disable-next-line
            let el = this;

            let ui = {
                id: "clientsTable",
                view: "datatable",
                css: "datatableUsers webix_header_border webix_data_border",
                autoheight: true,
                //hover: "datatableHover",
                minHeight: 60,
                //minColumnWidth: 120,
                rowHeight: 40,
                scrollY: false,
                scrollX: true,
                resizeColumn: true,
                select: false,
                pager: {
                    template: "{common.prev()} {common.pages()} {common.next()}",
                    container: "clientPaging",
                    css: { width: "100% !important" },
                    size: 15,
                    group: 5,
                },
                columns: [
                    {
                        id: "actions",
                        header: "Actions",
                        width: 120,
                        template: function () {
                            return `
                        <div class="tableIcons">
                            <i class="mdi mdi-trash-can deleteAction"></i>
                            <i class="mdi mdi-eye viewAction"></i>
                            &nbsp;&nbsp;
                            </div>`;
                        },
                    },
                    {
                        id: "member_name",
                        header: ["Member Name", { content: "textFilter" }],
                        width: 180,
                    },
                    {
                        id: "member_number",
                        header: ["Member Number", { content: "textFilter" }],
                        width: 180,
                    },
                    {
                        id: "emails",
                        header: ["Email", { content: "textFilter" }],
                        width: 180,
                    },
                    /*{
                        id: "customer_no",
                        header: ["Customer No", { content: "selectFilter" }],
                        fillspace: 0.6,
                    },*/
                    {
                        id: "city",
                        header: ["City", { content: "selectFilter" }],
                        width: 180,
                    },
                    {
                        id: "postal_code",
                        header: ["Postal Code", { content: "selectFilter" }],
                        width: 180,
                    },
                    {
                        id: "province",
                        header: ["Province", { content: "selectFilter" }],
                        width: 180,
                    },
                    {
                        id: "address",
                        header: ["Address", { content: "textFilter" }],
                        width: 180,
                    },
                ],
                ready() {
                    // el.resize_event = window.webix.event(window, "resize", function () {
                    //     if (el.ui.id)
                    //         el.$nextTick(() => {
                    //             window.webix.$$(el.ui.id).refresh();
                    //         });
                    // });
                },
                onClick: {
                    //eslint-disable-next-line
                    // viewAction: function (id, e, trg) {
                    //     //NB: this.getItem(e.row) will return the id if it is in the object in the store
                    //     let el = this.$scope.$parent;
                    //     el.setSelectedClientId(e.row);
                    // },
                    //eslint-disable-next-line
                    viewAction: function (id, e, trg) {
                        //NB: this.getItem(e.row) will return the id if it is in the object in the store
                        let el = this.$scope.$parent;

                        let idForUrl = e.row;

                        el.setSelectedClientId(e.row);

                        el.$router.push({
                            path: `/clients/view/${idForUrl}`,
                            param: { idForUrl },
                        });
                    },

                    //eslint-disable-next-line
                    deleteAction: function (id, e, trg) {
                        const el = this.$scope.$parent;
                        const item = this.getItem(e.row);

                        el.handleSwal(item, e);
                    },
                },
            };
            return ui;
        },
    },
    methods: {
        ...clientMethods,
        handleSwal(item, e) {
            Swal.fire({
                title: `Are you sure you want to delete
                            "${item.member_name}" ?`,
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete!",
            }).then((result) => {
                if (result.value) {
                    this.ready = false;
                    this.deleteClient({ id: e.row }).then(() => {
                        // el.getStockists().then(() => {
                        //     el.ready = true;
                        // });
                        Swal.fire("Member was deleted", "", "success");

                        //refresh the table on the screen
                        this.getClients();
                    });
                }
            });
        },
    },
    mounted() {
        //get the data for the table
        if (this.clients.length == 0) {
            this.getClients();
        }
    },
};
</script>

<style lang="scss" scoped></style>
